import React, { useCallback, useState, useContext, useEffect } from "react";

import styled, { css } from "styled-components";
import Image from "gatsby-image";
import { myContext } from "../provider";
import {
  Container,
  TextReveal,
  RotatingButton,
  Headline,
  Paragraph,
  Layout,
  SEO,
} from "../components";

const Section = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  ${(props) => props.style}
`;

const FullBackgroundImage = styled(Image)`
  opacity: 0.12;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
`;

const Space = styled.div`
  margin: 40px 0;
`;

const ShowSupportContainer = styled.div`
  position: relative;
  transition: all 0.3s ease max-height 0.3s ease 0.3s;
  ${(props) =>
    props.hide &&
    css`
      transform: translateY(40px);
      opacity: 0;
      max-height: 0;
    `}
`;

const ThankYouContainer = styled.div`
  transition: all 0.3s ease 0.5s;
  transform: translateY(40px);
  opacity: 0;
  ${(props) =>
    props.show &&
    css`
      transform: translateY(0px);
      opacity: 1;
    `}
`;

const BlogPage = ({ data }) => {
  const [hasSupportMe, set_hasSupportMe] = useState(false);
  const handleAddBlogSupport = useCallback(() => {
    set_hasSupportMe(true);
    fetch(
      "https://script.google.com/macros/s/AKfycbz8xmYcEInqusAZyix3ZhQEzFID_2pvDA0YRHYnKUUNHVYxWCQ7/exec"
    )
      .then((response) => response.text())
      .then((text) => console.log(text));
  });
  const context = useContext(myContext);

  const onLoad = () => {
    context.set_pageSections([]);
  };

  useEffect(onLoad, []);

  return (
    <Layout>
      <SEO
        title="Thomas Morice: My blog"
        description="My blog doesn't currently exist, but by clicking on a button in this page, you can support me maybe convince me to finally start my blog"
      />
      <Section>
        <FullBackgroundImage
          style={{ position: "fixed" }}
          fluid={data.file.childImageSharp.fluid}
        />
        <Container style={{ zIndex: "9999" }}>
          <div>
            <Headline
              leftElem={<div>WOOPS </div>}
              rightElem={<div>I don't have a blog yet</div>}
            ></Headline>
          </div>
          <div>
            <h1>
              <TextReveal animateOut={hasSupportMe} rotate>
                When I find the time and
              </TextReveal>
              <TextReveal animateOut={hasSupportMe} rotate delay={0.11}>
                the courage, this page
              </TextReveal>
              <TextReveal animateOut={hasSupportMe} rotate delay={0.19}>
                will be a blog.
              </TextReveal>
              <TextReveal animateIn={hasSupportMe} delay={0.3} rotate>
                Thank you!
              </TextReveal>
              <TextReveal animateIn={hasSupportMe} rotate delay={0.36}>
                I deeply appreciate it!
              </TextReveal>
            </h1>
          </div>

          <ShowSupportContainer hide={hasSupportMe}>
            <Space />
            <Paragraph smallVersion>
              As weird as it might sound, I’m scared of creating a blog, even
              though I always wanted one. I’m afraid I will not be interesting
              enough, or I won’t deliver enough... Maybe... Maybe I might need
              that little push from you.
            </Paragraph>
            <Space />
            <Paragraph smallVersion>
              Hit that button if you think I should do one!
            </Paragraph>
            <Space />
            <div style={{ width: "80%", maxWidth: "354px" }}>
              <RotatingButton
                frontText={"Do it!"}
                backText={"I support ✊"}
                onClick={handleAddBlogSupport}
              />
            </div>
          </ShowSupportContainer>
          <ThankYouContainer show={hasSupportMe}>
            <Paragraph smallVersion>
              Whoever you are, this means a lot. I have plenty of ideas already
              for my blog. I want it to be focused a bit less on tech, but
              rather on soft skills, philosophy etc..
            </Paragraph>
            <Space />
            <Paragraph smallVersion>
              Don’t hesitate to visit this page again, you might actually see a
              real blog 😊
            </Paragraph>
          </ThankYouContainer>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query backgroundImage {
    file(relativePath: { eq: "thomas-morice-background-image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
          originalName
        }
      }
    }
  }
`;

export default BlogPage;
